import { FC, useMemo } from "react";
import { Button, Divider, Dropdown, Tooltip } from "antd";
import { observer } from "mobx-react";

import { Icon } from "elements/icon/icon";
import { InputNumber } from "elements/inputs/inputNumber/inputNumber";
import { CalculateSettings } from "models/project/fact/forecast/injPrediction/calculateSettings";
import { InjectionResults } from "models/project/fact/forecast/injPrediction/injectionResults";
import { InputParams } from "models/project/fact/forecast/injPrediction/inputParams";
import { InjectionPredictionSettings } from "services/back/injectionPrediction/calculateInjection";

import { ReactComponent as ArrowDownIcon } from "./icons/arrowDown.svg";
import { ReactComponent as ResetIcon } from "./icons/reset.svg";

import cn from "./inputParams.module.less";

type CalculationMode = InjectionPredictionSettings["mode"];

type CalculationModeDropdownItem = {
  key: CalculationMode;
  label: string;
  disabled?: boolean;
  tooltipMessages?: string[];
};

const TOOLTIPS = {
  disabledWithDecline: [
    "Выполнить расчет без учета выбытия нагнетательных скважин",
    "Задать значение параметров: мин. приемистость, макс. радиус влияния, интервал изменения закачки",
  ],
  nullParams: ["Задать значение параметров: мин. приемистость, макс. радиус влияния, интервал изменения закачки"],
  notCalculated: ["Выполнить расчет без учета выбытия нагнетательных скважин"],
};

const createTooltipContent = (items: string[]) => (
  <div>
    <p>Для расчета с учетом выбытия необходимо:</p>
    <ul>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </div>
);

const SubFrameTitle: FC<{ store: InputParams; results: InjectionResults; disabled: boolean }> = observer(({ store, results, disabled }) => {
  const [mode, settings] = useMemo(() => {
    const resultSettings = results.calculatedResultsSettings;
    const mode = resultSettings?.mode ?? null;
    const initParams = resultSettings?.mode === "decline" ? resultSettings.params : undefined;
    return [mode, new CalculateSettings(initParams)];
  }, [results.calculatedResultsSettings]);

  const disabledDecline = mode === null || !settings.isValid;

  const getTooltipMessages = (): string[] | undefined => {
    if (mode === null && !settings.isValid) {
      return TOOLTIPS.disabledWithDecline;
    } else if (!settings.isValid) {
      return TOOLTIPS.nullParams;
    } else if (mode === null) {
      return TOOLTIPS.notCalculated;
    }
    return undefined;
  };

  const calculationModes: CalculationModeDropdownItem[] = [
    { key: "plain", label: "Без учета выбытия нагнетательных скважин" },
    { key: "decline", label: "С учетом выбытия нагнетательных скважин", disabled: disabledDecline, tooltipMessages: getTooltipMessages() },
  ];

  const handleCalculate = async (key: CalculationMode) => {
    await store.saveCompensation();

    if (key === "plain") {
      await results.calculate({ mode: key });
    } else if (key === "decline") {
      const { validatedParams } = settings;
      if (validatedParams === undefined) {
        console.error("unreachable");
        return;
      }
      await results.calculate({ mode: key, params: validatedParams });
    }
  };

  return (
    <div className={cn.subFrameTitle}>
      <div className={cn.inputCustomWrapper}>
        <div>Мин. приемистость</div>
        <InputNumber
          bordered
          placeholder="м3/сут"
          value={settings.inputParams.injCapacity ?? undefined}
          onUpdate={(value) => settings.setInputParams({ injCapacity: value })}
        />
      </div>
      <Button
        icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ResetIcon />} />}
        onClick={() => settings.setInputParams({ injCapacity: null })}
      />

      <Divider type="vertical" className={cn.divider} />
      <div className={cn.inputCustomWrapper}>
        <div>Макс. радиус влияния</div>
        <InputNumber
          bordered
          placeholder="м"
          value={settings.inputParams.radius ?? undefined}
          onUpdate={(value) => settings.setInputParams({ radius: value })}
          disabled
        />
      </div>
      <Button
        icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ResetIcon />} />}
        disabled
        onClick={() => settings.setInputParams({ radius: null })}
      />

      <Divider type="vertical" className={cn.divider} />
      <div className={cn.inputCustomWrapper}>
        <div>Интервал изменения закачки</div>
        <InputNumber
          bordered
          placeholder="мес"
          value={settings.inputParams.injPeriod ?? undefined}
          max={120}
          onUpdate={(value) => settings.setInputParams({ injPeriod: value })}
          disabled
        />
      </div>
      <Button
        icon={<Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ResetIcon />} />}
        disabled
        onClick={() => settings.setInputParams({ injPeriod: null })}
      />

      <div className={cn.btnWrapper}>
        <Dropdown
          disabled={disabled}
          menu={{
            items: calculationModes.map((item) => ({
              key: item.key,
              disabled: item.disabled,
              label:
                item.tooltipMessages !== undefined ? (
                  <Tooltip title={createTooltipContent(item.tooltipMessages)} placement="top">
                    <span>{item.label}</span>
                  </Tooltip>
                ) : (
                  item.label
                ),
            })),
            onClick: (v) => handleCalculate(v.key as CalculationMode),
          }}
        >
          <Tooltip title={!store.isValid ? "Данные содержат пересекающиеся периоды. Запуск расчета невозможен" : undefined}>
            <Button loading={results.isCalculating} type="primary" className={cn.calculateBtn} disabled={disabled}>
              Рассчитать <Icon width="16px" height="16px" viewBox="0 0 16 16" content={<ArrowDownIcon />} />
            </Button>
          </Tooltip>
        </Dropdown>
      </div>
    </div>
  );
});

export { SubFrameTitle };
