import { req } from "utils/request";

import { backendStorage } from "../backendStorage";

const INPUT_PARAMS_KEY = "inj_prediction_input_params" as const;

type InjectionDataBack = {
  well_id: number;
  stratum_id: number;
  scenario_id: number;
  gtm_id: number | null;
  forecast: {
    month: number[];
    year: number[];
    oil_rate: number[];
    liquid_rate: number[];
    oil_prod: number[];
    liquid_prod: number[];
    prod_days: number[];
    water_inj_m3_rate: number[];
    water_inj_m3: number[];
    inj_days: number[];
  };
};

type CalculationInput = {
  [producingObjectId: number]: {
    [prodWellId: number]: number;
  };
};

type InjectionPredictionSettings =
  | {
      mode: "decline";
      params: {
        injCapacity: number;
        radius: number;
        injPeriod: number;
      };
    }
  | {
      mode: "plain";
    };

function calculateInjection(scenarioId: number, body: CalculationInput, settings: InjectionPredictionSettings) {
  const queryParams = settings.mode === "decline" ? `?water_inj_rate_threshold=${settings.params.injCapacity}` : "";
  return req.post<InjectionDataBack[]>(`calculation/injection/${scenarioId}${queryParams}`, body);
}

const saveInputParams = async (
  data: InjectionPredictionSettings | null,
  projectId: number,
  scenarioId: number
): Promise<InjectionPredictionSettings | null> =>
  await backendStorage.setItem(data, INPUT_PARAMS_KEY, projectId, scenarioId, true);

const getInputParams = async (projectId: number, scenarioId: number): Promise<InjectionPredictionSettings | null> => {
  return await backendStorage.getItem(INPUT_PARAMS_KEY, projectId, scenarioId);
};

export type { CalculationInput, InjectionDataBack, InjectionPredictionSettings };
export { calculateInjection, getInputParams, saveInputParams };
