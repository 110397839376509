import { FC, useCallback, useMemo, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Map as MapContext, MapTooltipCursor } from "@okopok/axes_context";
import { MapManager } from "@okopok/axes_context";
import { Button } from "antd";
import classNames from "classnames";
import { observer } from "mobx-react";
import { PageFrameTitlePortal } from "routing/pageFrame/pageFrameTitlePortal";

import { DatePane } from "elements/datePane/datePane";
import { Icon } from "elements/icon/icon";
import { Notification } from "elements/notification/notification";
import { useForecast } from "models/project/fact/forecast/forecast";

import { CalculateButton } from "../calculateButton/calculateButton";
import { ImportMines } from "../importMines/importMines";

import { ReactComponent as ShowSideMenuIcon } from "./icons/showSideMenu.svg";
import { InfrastructureMapContextProvider, NodeType, useInfrastructureMapContext } from "./InfrastructureMapManager/InfrastructureMapManager";
import { InspectorMenu } from "./inspectorMenu/inspectorMenu";
import { MapToolbar } from "./mapToolbar/mapToolbar";
import Selection from "./selection/selection";
import CreateTopologies from "./createTopologies";
import { ImportOis } from "./importOis";
import { LegendMap } from "./legendMap";
import Ruler from "./Ruler";
import { calculateDistance, tooltipText } from "./utils";

import cn from "./InfrastructureMap.module.less";
const SCALE_EXTENT = { min: 0.5, max: 16000 } as const;

const keyCode = {
  KeyC: "selection",
  KeyT: "create",
  KeyL: "ruler",
} as const;

const InfrastructureMap: FC = observer(() => {
  const [manager, setManager] = useState<MapManager | null>(null);
  const [showInspectorMenu, setShowInspectorMenu] = useState(true);

  const fc = useForecast();
  const dataManager = useInfrastructureMapContext();
  const { infrastructure, setCurrentDate } = dataManager;

  const distanceMeasurementText = useMemo(() => {
    const distance = calculateDistance({ from: dataManager.ruler, to: dataManager.zoomedCursorPosition });
    return `Расстояние: ${(distance || 0 * 100).toFixed(2)} м. Кликните еще раз, чтобы прекратить измерение`;
  }, [dataManager.ruler, dataManager.zoomedCursorPosition]);

  const isCreateWellPad = dataManager.isCreate && dataManager.isDrawing;
  const isMeasure = dataManager.isRuler && dataManager.ruler;

  const onEnter = useCallback(() => {
    if (dataManager.isSelection) {
      dataManager.selection.reset();
      dataManager.setTooltip(tooltipText.selectionStartTooltipText);
    }
    dataManager.isRuler && dataManager.setRuler(null);
  }, [dataManager]);

  const onEscape = useCallback(() => {
    if (dataManager.isSelection) {
      dataManager.selection.resetSelectedObects();
    } else {
      dataManager.resetCreation();
    }
  }, [dataManager]);

  const onDelete = useCallback(() => {
    if (dataManager.isSelection) {
      dataManager.removeSelectedItems();
    }
  }, [dataManager]);

  const onKeydown = (event: any) => {
    if (keyCode[event.code as keyof typeof keyCode]) {
      dataManager.updateInteractionMode(keyCode[event.code as keyof typeof keyCode]);
    }
    event.code === "Enter" && onEnter();
    event.code === "Escape" && onEscape();
    event.code === "Delete" && onDelete();
  };

  const handleCreateClick = (position: { x: number; y: number }) => {
    dataManager.setTooltip();
    dataManager.pushNode(position);
  };

  const handleSelectionCLick = (position: { x: number; y: number }) => dataManager.selection.update(position);

  const handleToMeasure = (position: { x: number; y: number }) => {
    dataManager.setRuler(position);
  };

  const updateCursor = (position: { x: number; y: number }, isZoom?: boolean, tooltip?: string) => {
    dataManager.updateCursorPosition(position, isZoom);
    dataManager.setTooltip(tooltip);
  };

  const handleMouseMove = (position: { x: number; y: number; zoomedX: number; zoomedY: number }) => {
    const zoomedPosition = { x: position.zoomedX, y: position.zoomedY };
    if (dataManager.isSelection) {
      updateCursor(zoomedPosition, true, !dataManager.selection.isGrab ? tooltipText.selectionStartTooltipText : undefined);
    } else if (dataManager.isRuler) {
      updateCursor(zoomedPosition, true, !dataManager.ruler ? tooltipText.rulerStartTooltipText : distanceMeasurementText);
    } else {
      if (dataManager.isDrawing) {
        if (!(dataManager.lineEnd as NodeType).uuid) {
          dataManager.setTooltip(tooltipText.createObjectConnectedPipeline);
        }
      } else {
        dataManager.setTooltip(`Кликните, чтобы создать объект с координатами: X: ${position.x.toFixed(2)} Y:${position.y.toFixed(2)}`);
      }
      dataManager.updateCursorPosition(position, false);
      dataManager.updateCursorPosition(zoomedPosition, true);
    }
  };

  const onClick = (position: { x: number; y: number }) => {
    if (dataManager.isCreate) {
      return handleCreateClick(position);
    } else if (dataManager.isSelection) {
      return handleSelectionCLick(position);
    } else {
      return handleToMeasure(position);
    }
  };

  return (
    <div className={cn.infrastructureWrap}>
      <div className={cn.layout} onKeyDown={onKeydown} tabIndex={0}>
        <MapToolbar manager={manager} />
        <LegendMap mode={dataManager.pipeColorMode} range={dataManager.range} />
        <PageFrameTitlePortal model={infrastructure} onSave={infrastructure.save} permissionSection="infrastructure">
          {fc && <ImportMines />}
          <Button
            type="text"
            onClick={() => setShowInspectorMenu((show) => !show)}
            icon={<Icon width="20px" height="20px" viewBox="0 0 20 20" content={<ShowSideMenuIcon />} />}
          />
          <Button type="default" danger onClick={dataManager.delete} icon={<DeleteOutlined />} />
          <ImportOis />
          {fc && <CalculateButton />}
        </PageFrameTitlePortal>
        <div className={cn.mapNDate}>
          <MapContext
            scaleExtent={SCALE_EXTENT}
            manager={setManager}
            viewPort={dataManager.viewPort}
            className={classNames(cn.map, {
              [cn.cursorNone]: isCreateWellPad || isMeasure,
              [cn.cursorSelection]: dataManager.isSelection,
              [cn.dragging]: dataManager.isDragging,
              [cn.selectedItem]: dataManager.inspector.selectedNodes && dataManager.inspector.selectedNodes?.length > 0,
            })}
            onMouseClick={(position) => onClick(position)}
            onMouseMove={handleMouseMove}
            onMouseLeave={() => dataManager.setTooltip()}
            ticksStep={150}
          >
            <CreateTopologies />
            <Selection />
            <Ruler />
            {!dataManager.selection.isGrab && dataManager.showMapTooltip && <MapTooltipCursor title={dataManager.tooltip} />}
          </MapContext>
          {fc?.fact.forecastDateRange && (
            <DatePane
              className={cn.datePane}
              onChange={setCurrentDate}
              value={infrastructure.currentDate}
              start={fc.fact.forecastDateRange.from}
              end={fc.fact.forecastDateRange.to}
            />
          )}
        </div>
      </div>
      <InspectorMenu visible={showInspectorMenu} />
      <Notification key="saveAlert" title="Ошибка" onClose={dataManager.setError}>
        {dataManager.error}
      </Notification>
    </div>
  );
});

const InfrastructureMapComponent: FC = () => (
  <InfrastructureMapContextProvider>
    <InfrastructureMap />
  </InfrastructureMapContextProvider>
);

export { InfrastructureMapComponent as InfrastructureMap };
