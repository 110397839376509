import { action, computed, makeObservable, observable } from "mobx";

import { InjectionPredictionSettings as Settings } from "services/back/injectionPrediction/calculateInjection";

type DeclineParams = (Settings & { mode: "decline" })["params"];

type InputParamsType = {
  [key in keyof DeclineParams]: DeclineParams[key] | null;
};

class CalculateSettings {
  public inputParams: InputParamsType;

  constructor(initParams?: InputParamsType) {
    makeObservable<CalculateSettings>(this, {
      inputParams: observable,
      validatedParams: computed,
      isValid: computed,
      setInputParams: action,
    });

    this.inputParams = initParams ?? {
      injCapacity: 20,
      radius: 500,
      injPeriod: 36,
    };
  }

  public get isValid(): boolean {
    return !Object.values(this.inputParams).some((v) => v === null);
  }

  public get validatedParams(): DeclineParams | undefined {
    if (!this.isValid) {
      return undefined;
    }
    return this.inputParams as DeclineParams;
  }

  public setInputParams(params: Partial<InputParamsType>) {
    this.inputParams = { ...this.inputParams, ...params };
  }
}

export type { InputParamsType };
export { CalculateSettings };
